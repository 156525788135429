import CustomerSelection from '../../../deals/components/CustomerSelection'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import { FormikErrors } from 'formik'
import { Button } from 'primereact/button'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Panel } from 'primereact/panel'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import FieldWrapper from '@/redesign/components/FieldWrapper/FieldWrapper'
import CustomerInfoCard from '@/redesign/domains/deals/components/CustomerPanel/CustomerInfoCard'
import {
  CustomDealContactData,
  CustomDealCreateArgs,
  Customer,
  ECustomDealStatusType,
} from '@/schemaTypes'

interface Props {
  contactData: CustomDealContactData
  customerId: string
  onChangeField: (field: string, value: string | null) => void
  status?: ECustomDealStatusType
  customer?: Customer
  errors?: FormikErrors<CustomDealCreateArgs>['contactData']
}

enum DIALOG {
  CHANGE_MAIN_CUSTOMER = 'CHANGE_MAIN_CUSTOMER',
}

export function CustomDealCustomer(props: Props) {
  const { contactData, customerId, onChangeField, customer, errors, status } =
    props
  const { t } = useTranslation()
  const [dialogMode, setDialogMode] = useState<DIALOG>()
  const [selectedCustomerId, setSelectedCustomerId] = useState<string | null>(
    null,
  )
  const [showUserInput, setShowUserInput] = useState(false)

  const isReadonly = [
    ECustomDealStatusType.Canceled,
    ECustomDealStatusType.Closed,
    ECustomDealStatusType.Declined,
    ECustomDealStatusType.Expired,
  ].includes(status)

  const handleOpenDialog = (mode) => {
    setDialogMode(mode)
  }

  const handleCloseDialog = () => {
    setDialogMode(undefined)
  }

  const onConfirmCustomerSelection = () => {
    if (selectedCustomerId) {
      onChangeField('customerId', selectedCustomerId)
      setSelectedCustomerId(null)
      onChangeField('contactData.email', '')
      onChangeField('contactData.phone', '')
      handleCloseDialog()
    }
  }

  return (
    <>
      <Panel header={t('customer.label')} className="w-max">
        <div className="flex flex-col gap-2">
          {(customer?._id || isReadonly) && (
            <CustomerInfoCard
              canEditCustomer={true}
              isGuestUser={false}
              isDealVerified={false}
              isMainCustomer={true}
              customer={customer}
              onChangeCustomer={() =>
                handleOpenDialog(DIALOG.CHANGE_MAIN_CUSTOMER)
              }
            />
          )}

          {!customerId && (
            <Message
              className="w-max"
              content={t('custom_deal_form.no_customer_connected')}
              severity="warn"
            />
          )}
          {!isReadonly && customerId && (
            <Button
              color="secondary"
              disabled={!customerId}
              onClick={() => onChangeField('customerId', null)}
              type="button"
              label={t('remove_customer')}
            />
          )}
          {!isReadonly && !customerId && (
            <Button
              onClick={() => handleOpenDialog(DIALOG.CHANGE_MAIN_CUSTOMER)}
              type="button"
              label={t('custom_deal_form.connect_customer')}
            />
          )}
          {!isReadonly &&
            !showUserInput &&
            !customerId &&
            !contactData.email && (
              <Button
                onClick={() => setShowUserInput(true)}
                type="button"
                severity="secondary"
                label={t('custom_deal_form.add_contact_info')}
                style={{
                  marginTop: 4,
                }}
              />
            )}
        </div>

        {!customerId && !isReadonly && (showUserInput || contactData.email) && (
          <div className="flex flex-col mt-4 gap-3">
            {/** will be fully replaced with FormikInput in future PR of CAS-4330 */}
            <FieldWrapper
              label={t('customer.email')}
              isInvalid={typeof errors === 'string' || !!errors?.email}
              helperText={errors?.email && t(errors?.email)}
            >
              <InputText
                value={contactData.email}
                disabled={!!customerId}
                onChange={(e) =>
                  onChangeField('contactData.email', e.target.value)
                }
              />
            </FieldWrapper>
            <FieldWrapper
              label={t('customer.phone')}
              isInvalid={!!errors?.phone}
              helperText={errors?.phone && t(errors?.phone)}
            >
              <InputText
                value={contactData.phone}
                disabled={!!customerId}
                onChange={(e) =>
                  onChangeField('contactData.phone', e.target.value)
                }
              />
            </FieldWrapper>
          </div>
        )}
      </Panel>

      <Dialog
        fullWidth={true}
        maxWidth={'sm'}
        open={!!dialogMode}
        onClose={() => handleCloseDialog()}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">
          {t('add_customer')}
        </DialogTitle>
        <DialogContent>
          <CustomerSelection
            selectedCustomerId={selectedCustomerId}
            setSelectedCustomerId={setSelectedCustomerId}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleCloseDialog()} color="primary">
            {t('abort')}
          </Button>

          <Button onClick={onConfirmCustomerSelection} color="primary">
            {t('custom_deal_form.connect_customer')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
