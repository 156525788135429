import {
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import ArrowBackIos from '@material-ui/icons/ArrowBackIos'
import { useFormik } from 'formik'
import { last, omit } from 'lodash'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import StatusHistory from '@/components/EventHistory'
import Loading from '@/components/Loading'
import NotePopup, { INoteBuild } from '@/components/Note/NotePopup'
import ShopSelect from '@/components/ShopSelect'
import WarningStolenItemsPopup from '@/components/WarningStolenItemsPopup'
import { Context } from '@/context'
import { useGetActiveCompanies, useGetCompany } from '@/domains/companies/hooks'
import { getCustomDealItemArgs } from '@/domains/customDealForms/helpers'
import {
  CustomDealItems,
  CustomDealOverview,
  CustomDealPayoutOverview,
  customDealSchema,
} from '@/domains/customDeals/components/CustomDealDetail'
import {
  checkIsCarPawn,
  sumTotalRequestedPayoutAmount,
} from '@/domains/customDeals/helpers'
import {
  useAddNewItem,
  useConvertToDeal,
  useGetCustomDealById,
  useRemoveItem,
  useUpdateCustomDeal,
  useUpdateItem,
} from '@/domains/customDeals/hooks'
import { useCheckCustomDealItemsPossiblyStolen } from '@/domains/customDeals/hooks/checkCustomDealItemsPossiblyStolen'
import { useOverwriteCustomDealPayoutAmountToCustomerRequested } from '@/domains/customDeals/hooks/useOverwriteCustomDealPayoutAmountToCustomerRequested'
import styles from '@/domains/customDeals/index.module.scss'
import { useGetCustomer } from '@/domains/customers/hooks'
import {
  includeHidedCategories,
  useGetItemCategories,
} from '@/domains/itemCategories/hooks'
import {
  useMutationShowingErrors,
  useParseErrors,
  useShowConfirmPopup,
} from '@/hooks'
import { useGetActiveEmployees } from '@/hooks/useGetActiveEmployees'
import { CustomerBlacklistWarning } from '@/redesign/components/CustomerBlacklistWarning/CustomerBlacklistWarning'
import {
  CustomDeal,
  CustomDealEmployeeResponseArgs,
  CustomDealItemDataEntry,
  CustomDealUpdateArgs,
  ECustomDealStatusType,
  EDealType,
} from '@/schemaTypes'
import { useAppSelector } from '@/store'

interface Props {
  customDealId: string
  onConfirm?: (deal: CustomDeal) => void
}

export const MINIMUM_CUSTOM_PAYOUT_AMOUNT = 50
const UPDATABLE_FIELD = [
  { field: 'companyId' },
  { field: 'status' },
  { field: 'contactData', omittedProps: ['blacklistInformation'] },
  { field: 'customerId' },
  { field: 'dealType' },
  { field: 'employeeId' },
  { field: 'employeeResponse' },
  { field: 'durationInDays' },
  { field: 'note' },
  { field: 'pickupTransportMode' },
  { field: 'shopId' },
  { field: 'payoutApproverId' },
]

export function CustomDealEditPage(props: Props) {
  const { customDealId } = props
  const { showInfo, language, closeConfirmModal, showErrors } =
    useContext(Context)
  const { t } = useTranslation()
  const [isLoading, setLoading] = useState(false)
  const [openNotePopup, setOpenNotePopup] = useState<boolean | undefined>()
  const { customDeal: customDealQueryResult, queryResult } =
    useGetCustomDealById({
      variables: { customDealId, languageCode: language },
    })
  const { itemCategories } = useGetItemCategories(includeHidedCategories)
  const showConfirmPopup = useShowConfirmPopup({
    actionText: t('change_status'),
  })
  const defaultShopId = useAppSelector(
    (state) => state.ui.employeeActiveShop,
  )?.id
  const [openWarningStolenItemsPopup, setOpenWarningStolenItemsPopup] =
    useState(false)
  const parseErrors = useParseErrors()

  const { companies } = useGetActiveCompanies()

  const showConfirmDeleteItemPopup = useShowConfirmPopup({
    actionText: t('yes'),
    abortActionText: t('no'),
  })

  const { customDealItemsPossiblyStolen } =
    useCheckCustomDealItemsPossiblyStolen({
      variables: {
        customDealId,
      },
      skip:
        !customDealQueryResult ||
        customDealQueryResult.events[customDealQueryResult.events.length - 1]
          ?.event !== ECustomDealStatusType.Created ||
        !!customDealQueryResult.customer?.isTestUser,
    })

  useEffect(() => {
    if (customDealItemsPossiblyStolen) {
      setOpenWarningStolenItemsPopup(true)
    }
  }, [customDealItemsPossiblyStolen])

  const getCustomDealUpdateArgs = useCallback(
    (customDeal) => {
      if (!customDeal || !customDealQueryResult) return null

      return UPDATABLE_FIELD.reduce(
        (result: CustomDealUpdateArgs, { field, omittedProps }) => {
          const initialValue = customDealQueryResult[field]
          const value = ['durationInDays'].includes(field)
            ? Number(customDeal[field])
            : customDeal[field]

          if (initialValue !== value) {
            result[field] = omittedProps ? omit(value, omittedProps) : value
          }

          return result
        },
        {} as CustomDealUpdateArgs,
      )
    },
    [customDealQueryResult],
  )

  const checkIfCustomDealNeedsApprover = () => {
    const asigneeEmployee = employees.find(
      (c) => c._id === customDeal.employeeId,
    )
    if (customDeal && asigneeEmployee) {
      if (
        customDeal.items?.some((c) => c.overwrittenItemValue) ||
        (customDeal.dealType === EDealType.Pawn &&
          !isCarPawn &&
          customDeal.pawnCalculation?.dealValuesEntry.payoutAmount >
            asigneeEmployee.payoutConfigurations?.payoutLimit) ||
        (customDeal.dealType === EDealType.Pawn &&
          isCarPawn &&
          customDeal.pawnCalculation?.dealValuesEntry.payoutAmount >
            asigneeEmployee.payoutConfigurations?.carPayoutLimit) ||
        (customDeal.dealType === EDealType.Purchase &&
          !isCarPawn &&
          customDeal.purchaseCalculation?.dealValuesEntry.payoutAmount >
            asigneeEmployee.payoutConfigurations?.payoutLimit) ||
        (customDeal.dealType === EDealType.Purchase &&
          isCarPawn &&
          customDeal.purchaseCalculation?.dealValuesEntry.payoutAmount >
            asigneeEmployee.payoutConfigurations?.carPayoutLimit)
      ) {
        return true
      }
    }
  }

  const onSaveCustomDeal = (customDeal: Partial<CustomDeal>) => {
    if (!customDeal || !customDealQueryResult) return

    if (
      [
        ECustomDealStatusType.Accepted,
        ECustomDealStatusType.Counteroffer,
      ].includes(customDeal.status)
    ) {
      if (
        customDeal.items.some(
          (c) =>
            !c.algoliaReference &&
            !c.variantId &&
            !c.vehicleData?.indicataId &&
            !c.foundComparableOffers,
        )
      ) {
        return showInfo(
          t('custom_deal_item_evaluation_is_not_done_on_all_items'),
        )
      }

      if (
        (customDeal?.dealType === EDealType.Pawn &&
          customDeal.pawnCalculation?.dealValuesEntry.payoutAmount <=
            MINIMUM_CUSTOM_PAYOUT_AMOUNT) ||
        (customDeal?.dealType === EDealType.Purchase &&
          customDeal.purchaseCalculation?.dealValuesEntry.payoutAmount <=
            MINIMUM_CUSTOM_PAYOUT_AMOUNT)
      ) {
        return showInfo(
          t('payout_amount_is_not_valid', {
            amount: MINIMUM_CUSTOM_PAYOUT_AMOUNT,
          }),
        )
      }

      for (const item of customDeal.items) {
        if (itemCategories.find((c) => c.parentId === item.itemCategoryId)) {
          return showInfo(t('custom_item_category_must_select_detail'))
        }
      }

      if (checkIfCustomDealNeedsApprover() && !customDeal.payoutApproverId) {
        return showInfo(t('payout_approver_is_not_selected'))
      }
    }

    const customDealUpdateArgs = getCustomDealUpdateArgs(customDeal)

    setLoading(true)

    updateCustomDeal({
      variables: {
        customDealUpdateArgs: {
          ...customDealUpdateArgs,
          _id: customDealId,
        },
      },
      onCompleted: () => {
        setLoading(false)
      },
      onError: (errors) => {
        setLoading(false)
        showErrors(parseErrors(errors))
      },
    })
  }

  const onConvertToDeal = async () => {
    setLoading(true)
    try {
      const customDealUpdateArgs = getCustomDealUpdateArgs(customDeal)

      await convertToDeal({
        variables: {
          customDealId,
          customDealUpdateArgs: customDealUpdateArgs
            ? {
                ...customDealUpdateArgs,
                _id: customDealId,
              }
            : undefined,
        },
      })
    } finally {
      setLoading(false)
    }
  }

  const formik = useFormik<Partial<CustomDeal>>({
    initialValues: {
      ...customDealQueryResult,
    },
    enableReinitialize: true,
    onSubmit: onSaveCustomDeal,
    validationSchema: customDealSchema,
  })

  const customDeal = formik.values

  const isCarPawn = checkIsCarPawn(customDeal.items)

  const { customer } = useGetCustomer({
    variables: {
      customerId: customDeal?.customerId,
    },
    skip: !customDeal?.customerId,
  })

  const { employees } = useGetActiveEmployees()

  const { company } = useGetCompany({
    fetchPolicy: 'cache-first',
    variables: {
      companyId: formik.values.companyId,
    },
    onCompleted: () => {
      if (
        formik.values.durationInDays &&
        formik.values.dealType === EDealType.Pawn &&
        formik.values.durationInDays < company.configuration.minimumPawnDuration
      ) {
        formik.setFieldValue(
          'durationInDays',
          company.configuration.minimumPawnDuration,
        )
      }
    },
    skip: !formik.values.companyId,
  })

  const status: ECustomDealStatusType | undefined = last(
    customDeal?.events,
  )?.event

  const updateCustomDeal = useMutationShowingErrors({
    mutation: useUpdateCustomDeal(),
    successMessage: t('update_successful'),
  })

  const overwriteCustomDealPayout = useMutationShowingErrors({
    mutation: useOverwriteCustomDealPayoutAmountToCustomerRequested(),
    successMessage: t('update_successful'),
  })

  const convertToDeal = useMutationShowingErrors({
    mutation: useConvertToDeal(),
    successMessage: t('convert_to_deal_successful'),
  })

  const removeItemCustomDeal = useMutationShowingErrors({
    mutation: useRemoveItem(),
    successMessage: t('update_successful'),
  })

  const addNewItem = useMutationShowingErrors({
    mutation: useAddNewItem(),
    successMessage: t('update_successful'),
  })

  const updateItem = useMutationShowingErrors({
    mutation: useUpdateItem(),
    successMessage: t('update_successful'),
  })

  const onCopyMagicLink = () => {
    const linkTarget = document.getElementById('magicLinkId')
    if (linkTarget) {
      const range = document.createRange()
      range.selectNode(linkTarget)
      const selection = window.getSelection()
      if (selection) {
        selection.removeAllRanges()
        selection.addRange(range)
        document.execCommand('copy')
        selection.removeAllRanges()
      }
    }
  }

  const checkValidCounterOfferDeal = (customDeal?: CustomDeal) => {
    return customDeal?.items.some(
      (item) =>
        item.counterofferPayoutAmount ||
        (customDeal.dealType === EDealType.Pawn &&
          customDeal.pawnCalculation?.dealValuesEntry.payoutAmount <
            totalRequestedPayoutAmount &&
          item.itemPawnValueEntry?.payoutAmount < item.pawnPayoutAmount) ||
        (customDeal.dealType === EDealType.Purchase &&
          customDeal.purchaseCalculation?.dealValuesEntry.payoutAmount <
            totalRequestedPayoutAmount &&
          item.itemPurchaseValueEntry?.payoutAmount <
            item.purchasePayoutAmount),
    )
  }

  const handleChangeReviewing = () => {
    if (customDeal.customerId && defaultShopId) {
      formik.setFieldValue('status', ECustomDealStatusType.Reviewing)
      return
    }

    const checkDisableConfirmButton = (state: {
      firstname?: string | null
      lastname?: string | null
      shopId?: string
    }) => {
      const isMissingUserName =
        !customDeal.customerId && (!state?.firstname || !state?.lastname)
      const isMissingShopId = !defaultShopId && !state.shopId

      return isMissingUserName || isMissingShopId
    }

    showConfirmPopup({
      state: {
        firstname: customDeal.contactData?.firstname || '',
        lastname: customDeal.contactData?.lastname || '',
        shopId: defaultShopId,
        disableRightButton: checkDisableConfirmButton(
          customDeal.contactData || {},
        ),
      },
      title: t('create_copy_of_certificate'),
      preventAutoCloseByKey: true,
      component: (state, setState) => {
        return (
          <div className={styles.reviewModal}>
            {!customDeal.customerId && (
              <div>
                <Typography className="u-pt-sm" gutterBottom>
                  {t('create_copy_of_certificate_description')}:
                </Typography>
                <div className={styles.form}>
                  <TextField
                    label={t('customer.firstname')}
                    fullWidth
                    value={state.firstname}
                    onChange={(e) => {
                      const newState = { ...state, firstname: e.target.value }
                      setState({
                        ...newState,
                        disableRightButton: checkDisableConfirmButton(newState),
                      })
                    }}
                    error={!state.firstname}
                  />
                  <TextField
                    label={t('customer.lastname')}
                    fullWidth
                    value={state.lastname}
                    onChange={(e) => {
                      const newState = { ...state, lastname: e.target.value }
                      setState({
                        ...newState,
                        disableRightButton: checkDisableConfirmButton(newState),
                      })
                    }}
                    error={!state.lastname}
                  />
                </div>
              </div>
            )}
            {!defaultShopId && (
              <div className={styles.shopSelection}>
                <ShopSelect
                  companyId={customDeal.companyId}
                  shopId={state.shopId}
                  onChange={(shopId) => {
                    const newState = { ...state, shopId }
                    setState({
                      ...newState,
                      disableRightButton: checkDisableConfirmButton(newState),
                    })
                  }}
                  useDefaultShopId
                  includeDisabled
                />
              </div>
            )}
          </div>
        )
      },
      action: async (state) => {
        if (!customDeal.customerId) {
          formik.setFieldValue('contactData.firstname', state.firstname)
          formik.setFieldValue('contactData.lastname', state.lastname)
        }
        formik.setFieldValue('shopId', state.shopId)

        formik.setFieldValue('status', ECustomDealStatusType.Reviewing)
        closeConfirmModal()
      },
    })
  }

  useEffect(() => {
    if (
      defaultShopId &&
      customDeal.status === ECustomDealStatusType.Reviewing
    ) {
      formik.setFieldValue('shopId', defaultShopId)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultShopId, customDeal.status])

  const onEventChange = (status: ECustomDealStatusType) => {
    if (
      status === ECustomDealStatusType.Counteroffer &&
      !checkValidCounterOfferDeal(customDealQueryResult)
    ) {
      showInfo(t('custom_deal_counteroffer_warning'))
      return formik.setFieldValue('status', customDeal.status)
    }

    if (
      status === ECustomDealStatusType.Accepted &&
      checkValidCounterOfferDeal(customDealQueryResult)
    ) {
      showInfo(t('custom_deal_accepted_warning'))
      return formik.setFieldValue('status', customDeal.status)
    }

    if (status === ECustomDealStatusType.Reviewing) {
      handleChangeReviewing()
      return
    }

    formik.setFieldValue('status', status)
  }

  const onChangeField = (
    field: string,
    value: string | number | CustomDealEmployeeResponseArgs | null,
  ) => {
    formik.setFieldValue(field, value)
  }

  const onRemoveItem = (index: number) => {
    const item = customDeal?.items?.[index]
    showConfirmDeleteItemPopup({
      title: t('delete_custom_item_confirm', {
        productName: item?.title ?? '',
      }),
      action: () => {
        if (!item) return

        removeItemCustomDeal({
          variables: {
            customDealRemoveItemArgs: {
              customDealId,
              customDealItemId: item._id,
            },
          },
        })
      },
    })
  }

  const onAddItem = (item: Omit<CustomDealItemDataEntry, '_id'>) => {
    setLoading(true)

    addNewItem({
      variables: {
        customDealId,
        customDealItemCreateArgs: getCustomDealItemArgs(item),
      },
      onCompleted: () => {
        setLoading(false)
      },
      onError: (errors) => {
        setLoading(false)
        showErrors(parseErrors(errors))
      },
    })
  }

  const onUpdateItem = (item: CustomDealItemDataEntry) => {
    const id = item._id
    setLoading(true)

    updateItem({
      variables: {
        customDealId,
        customDealItemUpdateArgs: {
          ...getCustomDealItemArgs(item),
          _id: id,
        },
      },
      onCompleted: () => {
        setLoading(false)
      },
      onError: (errors) => {
        setLoading(false)
        showErrors(parseErrors(errors))
      },
    })
  }

  const onOverwriteCustomDealPayout = () => {
    setLoading(true)
    overwriteCustomDealPayout({
      variables: {
        customDealId: customDeal._id,
      },
      onCompleted: () => {
        setLoading(false)
      },
      onError: (errors) => {
        setLoading(false)
        showErrors(parseErrors(errors))
      },
    })
  }

  const noteBuilds = useMemo(() => {
    const notePopupBuilds: INoteBuild[] = []
    if (customDeal?.customer?.note && customDeal.customer.isNotePopup) {
      notePopupBuilds.push({
        title: 'On Customer',
        notes: [
          {
            content: customDeal.customer.note,
          },
        ],
        hideUserInfo: true,
      })
    }

    if (customDeal?.notes?.length) {
      const popupNotes = customDeal.notes.filter((note) => note.isPopup)

      if (popupNotes.length) {
        notePopupBuilds.push({
          title: 'On CustomDeal',
          notes: popupNotes,
        })
      }
    }

    return notePopupBuilds
  }, [customDeal])

  const totalRequestedPayoutAmount = sumTotalRequestedPayoutAmount(
    customDeal.items ?? [],
    customDeal.dealType,
  )

  const minimumPawnDuration = company?.configuration.minimumPawnDuration ?? 30

  const customerBlacklistInformation =
    customDeal.customer?.blacklistInformation ??
    customDeal.contactData?.blacklistInformation

  useEffect(() => {
    if (
      (typeof openNotePopup !== 'boolean' && noteBuilds.length) ||
      customerBlacklistInformation?.isAddedToBlacklist
    ) {
      setOpenNotePopup(true)
    }
    // TODO: CQI-2 fix this violation of react-hooks/exhaustive-deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerBlacklistInformation?.isAddedToBlacklist])

  if (!customDealIsLoaded(customDeal)) return <Loading />

  return (
    <div className={`${styles.container}`}>
      {isLoading && (
        <div className={styles.loadingWrapper}>
          <CircularProgress size={40} variant="indeterminate" />
        </div>
      )}

      <div className={styles.backToList}>
        <Link to={`/inApp/CustomDeals`}>
          <ArrowBackIos />
          <span>{t('back_to_list')}</span>
        </Link>
      </div>

      <CustomDealOverview
        companies={companies ?? []}
        customDeal={customDeal}
        customer={customer}
        refetchCustomDeal={queryResult.refetch}
        onChangeField={onChangeField}
        onEventChange={onEventChange}
        setFieldError={formik.setFieldError}
        errors={formik.errors}
        employees={employees}
        isCarPawn={isCarPawn}
        totalPayoutAmount={totalRequestedPayoutAmount}
        minimumPawnDuration={minimumPawnDuration}
      />

      {[
        ECustomDealStatusType.Accepted,
        ECustomDealStatusType.Counteroffer,
      ].includes(status!) && (
        <div className="u-p-sm u-12/12 u-mb-sm">
          <Typography variant="h6" gutterBottom>
            {t('magic_link')}
          </Typography>

          <Typography variant="body1" gutterBottom>
            <a
              href={customDeal.link || ''}
              id="magicLinkId"
              target="_blank"
              rel="noreferrer"
            >
              {customDeal.link}
            </a>
            <Button
              className="u-ml-sm"
              variant="outlined"
              size="small"
              onClick={onCopyMagicLink}
            >
              {t('copy_link')}
            </Button>
          </Typography>
        </div>
      )}
      <Paper>
        <CustomDealItems
          companyId={customDeal.company._id}
          items={customDeal.items ?? []}
          dealType={customDeal.dealType}
          durationInDays={customDeal.durationInDays}
          minimumPawnDuration={minimumPawnDuration}
          isCarPawn={isCarPawn}
          onChangeField={onChangeField}
          onRemoveItem={onRemoveItem}
          onAddItem={onAddItem}
          onUpdateItem={onUpdateItem}
          errors={formik.errors.items}
          isLoading={isLoading}
        />
      </Paper>

      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Typography variant="h6" gutterBottom>
          {t('payout_and_fees')}
        </Typography>
        <CustomDealPayoutOverview
          customDeal={customDeal}
          loading={queryResult?.loading}
          onOverwriteCustomDealPayout={onOverwriteCustomDealPayout}
        />
      </Paper>

      <Paper className="u-p-sm u-12/12 u-mb-sm">
        <Typography variant="h6" gutterBottom>
          {t('history')}
        </Typography>
        <StatusHistory events={customDeal.events} />
      </Paper>

      {status &&
        ![ECustomDealStatusType.Closed, ECustomDealStatusType.Expired].includes(
          status,
        ) && (
          <form
            className={`u-flex u-jc-end ${styles.actionBar}`}
            onSubmit={formik.handleSubmit}
          >
            {status &&
              [
                ECustomDealStatusType.Accepted,
                ECustomDealStatusType.Counteroffer,
              ].includes(status) && (
                <Button
                  className={`${styles.actionButton} u-mr-sm`}
                  variant="outlined"
                  color="secondary"
                  size="large"
                  onClick={onConvertToDeal}
                  disabled={!customDeal.customerId}
                  style={{ width: 200 }}
                >
                  {t('save_and_convert_to_deal')}
                </Button>
              )}
            <Button
              className={`${styles.actionButton} u-mr-sm`}
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => formik.resetForm()}
              style={{ width: 200 }}
              type="button"
            >
              {t('reset')}
            </Button>
            <Button
              className={styles.actionButton}
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              style={{ width: 200 }}
              disabled={isLoading || !formik.dirty || !formik.isValid}
            >
              {t(
                [
                  ECustomDealStatusType.Accepted,
                  ECustomDealStatusType.Declined,
                  ECustomDealStatusType.Counteroffer,
                ].includes(customDeal.status as ECustomDealStatusType) &&
                  customDealQueryResult?.status !== customDeal.status
                  ? 'save_and_send_email'
                  : 'save',
              )}
            </Button>
          </form>
        )}
      <NotePopup
        customerBlacklistNote={
          <CustomerBlacklistWarning
            blacklistInformation={customerBlacklistInformation}
          />
        }
        noteBuilds={noteBuilds}
        open={!!openNotePopup}
        onClose={() => setOpenNotePopup(false)}
      />
      <WarningStolenItemsPopup
        open={!!openWarningStolenItemsPopup}
        onClose={() => setOpenWarningStolenItemsPopup(false)}
      />
    </div>
  )
}

const customDealIsLoaded = (
  customDeal: Partial<CustomDeal>,
): customDeal is CustomDeal => customDeal?._id
